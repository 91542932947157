<template>
  <v-container style="height: 92vh" fluid>
    <loader v-if="loading"></loader>
    <!-- открытие изображения -->
    <v-overlay z-index="100" :value="imgModalState" opacity="0.9">
      <v-card>
        <v-img max-height="800" max-width="800" :src="openImgUrl" />
        <v-card-actions>
          <v-btn color="teal" :href="openImgUrl" target="_blank"
            >Cкачать изображение</v-btn
          >
          <v-spacer />
          <v-btn color="teal" @click="imgModalState = false">
            закрыть окно
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- добавление баннера -->
    <v-overlay z-index="100" :value="overlay">
      <v-card>
        <v-card-title>Добавить Баннер</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="input.name"
            :counter="20"
            label="Название Баннера"
          ></v-text-field>
          <div>Нужно установить оба изображения</div>
          <div>Размер файла не должен превышать 500кб</div>
          <div>Сервис для сжатия картинок:</div>
          <a target="_blank" href="https://imagecompressor.com/ru/"
            >https://imagecompressor.com/ru/</a
          >
          <v-file-input
            v-model="input.mobile"
            accept="image/*"
            label="Banner Mobile"
          />
          <v-file-input
            v-model="input.desktop"
            accept="image/*"
            label="Banner Desktop"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="white--text"
            color="teal"
            :disabled="requiredAtleastOneBanner || loadingData"
            @click="
              isCategoryBanners ? addBannerToCategory() : addBannerToCity()
            "
          >
            Ок
          </v-btn>
          <v-btn
            :disabled="loadingData"
            class="white--text"
            color="teal"
            @click="closeOverlay()"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
    <!-- основной блок -->
    <v-card class="mb-5">
      <v-card-title
        >Баннеры<v-spacer></v-spacer>
        <v-btn
          v-if="changeBannerPositionBtnState && changeButtonState"
          color="primary"
          class="mr-5"
          @click="clickChangeBtn()"
          >Изменить порядок</v-btn
        >
        <v-btn
          v-if="changeButtonState"
          color="primary"
          class="mr-5"
          @click="overlay = !overlay"
          >Добавить баннер</v-btn
        >
        <div style="width: 300px">
          <v-select
            v-model="chosenDepartment"
            hide-details
            label="Баннеры для ..."
            dense
            outlined
            :items="cityList"
            item-text="name"
            item-value="id"
          ></v-select>
        </div>
      </v-card-title>
    </v-card>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Инфо</th>
            <th class="text-left">Mobile</th>
            <th class="text-left">Desktop</th>
            <th class="text-left">Действия</th>
          </tr>
        </thead>
        <draggable
          v-model="bannerList"
          tag="tbody"
          :disabled="!changeButtonState"
        >
          <tr
            v-for="bannerItem in bannerList"
            :key="bannerItem.bannerId + bannerItem.categoryId"
          >
            <td>
              <div>
                <p>Имя: {{ bannerItem.bannerName }}</p>
                <p v-if="bannerItem.bannerType">
                  Тип: {{ bannerItem.bannerType.name }}
                </p>
                <p v-if="bannerItem.productId">
                  ID продукта: {{ bannerItem.productId }}
                </p>
                <p v-if="bannerItem.categoryId && !isCategoryBanners">
                  ID Категории: {{ bannerItem.categoryId }}
                </p>
                <p v-if="bannerItem.categoryName">
                  Категория: {{ bannerItem.categoryName }}
                </p>
                <p v-if="bannerItem.linkedCategoryId">
                  Категория для перехода: {{ bannerItem.linkedCategoryName }}
                </p>
              </div>
            </td>
            <td>
              <v-img
                max-height="150"
                max-width="250"
                :src="generateUrl(bannerItem.bannerId, true)"
                @dblclick="openImage(generateUrl(bannerItem.bannerId, true))"
              />
            </td>
            <td>
              <v-img
                max-height="150"
                max-width="250"
                :src="generateUrl(bannerItem.bannerId, false)"
                @dblclick="openImage(generateUrl(bannerItem.bannerId, false))"
              />
            </td>
            <td>
              <v-btn
                v-if="deleteBannerBtnState"
                icon
                color="error"
                @click="functionDeleteBanner(bannerItem.bannerId)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
              <v-btn color="primary" icon @click="addDataToBanner(bannerItem)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-switch
                :input-value="bannerItem.published"
                inset
                hide-details
                :label="bannerItem.published ? 'Скрыть' : 'Показать'"
                @change="changeVisibility(bannerItem.bannerId)"
              ></v-switch>
            </td>
          </tr>
        </draggable>
      </template>
    </v-simple-table>
    <RedactBannerDialog
      :redact-modal.sync="redactModal"
      :redacting-banner="redactingBanner"
      :categoryes="categoryes"
      :is-category-banners="isCategoryBanners"
      :chosen-department="chosenDepartment"
      @updateBanners="updateBanners"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import loader from "../components/Loader";
import RedactBannerDialog from "../components/Banners/RedactBannerDialog";
import ShowMessage from "@/Functions/message";
import api from "@/api";
export default {
  components: {
    draggable,
    loader,
    RedactBannerDialog,
  },
  data() {
    return {
      loading: false,
      cityList: [
        { id: 5, name: "Караганда", cityId: 1 },
        { id: 1132, name: "Темиртау", cityId: 2 },
        { id: 35, name: "Астана", cityId: 3 },
        { id: -1, name: "Категорий в Караганде" }, //для категорий нужно передевать айди города а не департамента
        { id: -2, name: "Категорий в Темиртау" },
        { id: -3, name: "Категорий в Астане" },
      ],
      chosenDepartment: null,
      changeButtonState: false,
      changeBannerPositionBtnState: false,
      deleteBannerBtnState: true,
      overlay: false,
      input: {},
      openImgUrl: null,
      imgModalState: false,
      loadingData: false,
      redactModal: false,
      categoryes: [],
      redactingBanner: null,
      isCategoryBanners: false,
    };
  },
  computed: {
    ...mapGetters({
      BANNER: "Banner/STATE",
      CATEGORY: "Category/ALL_CATEGORY",
    }),
    bannerList: {
      get() {
        return this.BANNER.allBanners;
      },
      set(value) {
        this.changeBannerPositionBtnState = true;
        this.$store.commit("Banner/UPDATE_BANNERS_POSITION", value);
      },
    },
    requiredAtleastOneBanner() {
      if (
        (this.input.desktop || this.input.mobile) &&
        this.input.name &&
        this.input.name.length >= 3 &&
        this.input.name.length <= 20
      ) {
        let check = false;
        if (this.input.desktop && this.input.desktop.size > 500000) {
          check = true;
        }
        if (this.input.mobile && this.input.mobile.size > 500000) {
          check = true;
        }
        return check;
      } else {
        return true;
      }
    },
  },
  watch: {
    // при выборе города подгружаются баннера
    async chosenDepartment() {
      this.loading = true;
      this.changeBannerPositionBtnState = false;
      this.deleteBannerBtnState = true;
      this.changeButtonState = true;
      // чтобы различать айдишники для всех баннеров и категорийных, сделал их отрицательными
      if (this.chosenDepartment < 0) {
        this.GET_CATEGORY_BANNERS(this.chosenDepartment * -1);
        this.isCategoryBanners = true;
      } else {
        await this.getBannersByDepartment(this.chosenDepartment);
        this.isCategoryBanners = false;
      }
      this.loading = false;
    },
  },
  // сразу находит нужный департамент на основе выбранного города
  mounted() {
    const currentCity = this.cityList.find(
      (item) => item.cityId === this.$root.city
    );
    this.chosenDepartment = currentCity.id;
  },
  methods: {
    ...mapActions({
      getBannersByDepartment: "Banner/GET_BANNER_LIST_BY_DEPARTMENT",
      getBannerImage: "Banner/GET_BANNER_IMAGE",
      deleteBanner: "Banner/DELETE_BANNER",
      changePositionsOfBanners: "Banner/UPDATE_BANNER_LIST",
      createBanner: "Banner/CREATE_BANNER",
      addImgBanner: "Banner/ADD_BANNER_IMAGE",
      getCategory: "Category/GET_ALL_CATEGORY",
      GET_CATEGORY_BANNERS: "Banner/GET_CATEGORY_BANNERS",
    }),
    generateUrl(id, mode) {
      mode = mode ? "mobile" : "desktop";
      let url = process.env.VUE_APP_API_URL;
      url += `admin/banner/get/image/${id}/${mode}`;
      return url;
    },
    clickChangeBtn() {
      this.changeBannerPositionBtnState = false;
      this.changePositionsOfBanners();
    },
    // создание нового баннера
    async addBannerToCity() {
      this.loadingData = true;
      this.input.departmentId = this.chosenDepartment;
      let responseBanner = await this.createBanner(this.input);
      this.input.id = responseBanner.data.id;
      let responseImage = await this.addImgBanner(this.input);
      if (responseImage === "success") {
        this.overlay = false;
        await this.getBannersByDepartment(this.chosenDepartment);
        this.input = {};
        ShowMessage("Баннер успешно добавлен", true);
      } else {
        await this.deleteBanner(this.input.id);
        this.overlay = false;
        ShowMessage(
          "Не удалось загрузить баннер на сервер, отключите addblock"
        );
      }
      this.loadingData = false;
    },
    // добавление баннера категории
    async addBannerToCategory() {
      this.loadingData = true;
      const currentCity = this.cityList.find(
        (item) => item.cityId === this.chosenDepartment * -1
      );
      let responseBanner = await api.Banner.createCategoryBanner({
        name: this.input.name,
        departmentId: currentCity.id,
      });
      this.input.id = responseBanner.id;
      let responseImage = await this.addImgBanner(this.input);
      if (responseImage === "success") {
        this.overlay = false;
        await this.GET_CATEGORY_BANNERS(this.chosenDepartment * -1);
        this.input = {};
        ShowMessage("Баннер успешно добавлен", true);
      } else {
        await this.deleteBanner(this.input.id);
        this.overlay = false;
        ShowMessage(
          "Не удалось загрузить баннер на сервер, отключите addblock"
        );
      }
      this.loadingData = false;
    },
    closeOverlay() {
      this.overlay = false;
      this.input = {};
    },
    async functionDeleteBanner(bannerID) {
      await this.deleteBanner(bannerID);
      this.updateBanners();
    },
    openImage(url) {
      this.openImgUrl = url;
      this.imgModalState = true;
    },
    async addDataToBanner(banner) {
      if (!this.CATEGORY.length) {
        this.loading = true;
        await this.getCategory(0);
        this.loading = false;
      }
      this.categoryes = this.CATEGORY;
      this.redactModal = true;
      this.redactingBanner = banner;
    },
    async changeVisibility(bannerId) {
      this.loading = true;
      let response = await api.Banner.changeBannerVisibility(bannerId);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Видимость баннера изменена", true);
        this.updateBanners();
      }

      this.loading = false;
    },
    updateBanners() {
      if (this.isCategoryBanners) {
        this.GET_CATEGORY_BANNERS(this.chosenDepartment * -1);
      } else {
        this.getBannersByDepartment(this.chosenDepartment);
      }
    },
  },
};
</script>
